body {
    --primary: #1973CE;
    --primary-light: #dfdae7;
    --foreground: #000000;
    --secondary: #1973CE;
    --background: #ffffff;
    --background-dim: #F5F5F5;
    --background-dim-light: #F5F5F5;
}

.svc-dragged-element-shortcut {
    height: auto !important;
}
  
.svc-notifier {
    position: absolute;
    bottom: var(--sjs-base-unit, var(--base-unit, 8px));
    left: var(--sjs-base-unit, var(--base-unit, 8px));
    background: #388E3C;
    opacity: 1;
    padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(2*(var(--sjs-base-unit, var(--base-unit, 8px))));
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: calc(0.5*(var(--sjs-base-unit, var(--base-unit, 8px))));
    color: #ffffff;
    min-width: calc(30*(var(--sjs-base-unit, var(--base-unit, 8px))));
    text-align: center;
    z-index: 1600;
    visibility: hidden;
    --small-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    font-style: normal;
    font-weight: 800;
    font-size: calc(.75*var(--small-bold-font-size));
    line-height: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
}

.svc-notifier.svc-notifier--shown {
    opacity: 1;
}